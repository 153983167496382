import React, { useState } from 'react';
import './Servicespage1.css';

function ServicesPage() {
  // Initial state to hold the search query and filtered services
  const [searchTerm, setSearchTerm] = useState('');

  // List of services with their titles, descriptions, and image paths
  const services = [
    { 
      "title": "Strip and Wax Floor Cleaning", 
      "desc": "Strip and wax floor cleaning is a comprehensive maintenance process designed to restore and protect hard surface floors. This method involves removing the existing layers of wax and buildup from the floor using a stripping solution and a floor machine. Once the floor is stripped and thoroughly cleaned, a fresh coat of wax is applied to enhance the shine, protect the surface, and create a durable finish. This process not only improves the appearance of the floor but also extends its lifespan, making it ideal for high-traffic areas in commercial and residential settings.", 
      "image": "./StripandWax.jpg" 
    },
    { 
      "title": "Construction Clean Up", 
      "desc": "Construction clean up is a specialized cleaning service performed after construction or renovation projects to remove debris, dust, and hazardous materials. This process typically includes the removal of large debris, thorough vacuuming and dusting of surfaces, cleaning windows, and sanitizing restrooms and kitchens. The goal is to create a safe and clean environment for occupancy, ensuring that all surfaces are free of construction residue and ready for use. This service is essential for contractors, property owners, and businesses looking to prepare a newly constructed or remodeled space for its intended purpose.", 
      "image": "./ConstructionCleanUp.jpg" 
    },
    { 
      title: "Commercial Cleaning", 
      desc: "Comprehensive cleaning solutions for businesses, ensuring a pristine environment for employees and clients.", 
      image: "./CommercialCleaning.jpg" 
    },
    { 
      title: "Industrial Cleaning", 
      desc: "Specialized cleaning services designed for factories and industrial facilities, focusing on safety and compliance.", 
      image: "./IndustrialCleaning.jpg" 
    },
    { 
      title: "Providing Supplies", 
      desc: "Delivery of high-quality cleaning supplies and equipment to keep your space spotless and well-maintained.", 
      image: "./SuppliesPic.jpg" 
    },
    { 
      title: "Home and Apartment Cleaning", 
      desc: "Thorough cleaning services for residential spaces, tailored to meet the unique needs of each home.", 
      image: "./CleaningHomes.jpg" 
    },
    { 
      title: "Professional Carpet Cleaning", 
      desc: "Expert stain removal and deep cleaning for carpets, revitalizing their appearance and extending their lifespan.", 
      image: "./CleanCarpets.jpg" 
    },
    { 
      title: "Car Dealership Cleaning", 
      desc: "Specialized cleaning for car dealerships, ensuring vehicles and showrooms are always in top condition.", 
      image: "./CleanCarDealerShip.jpg" 
    },
    { 
      title: "Retail Store Cleaning", 
      desc: "Maintaining a clean and inviting atmosphere in retail spaces to enhance customer experience and satisfaction.", 
      image: "./retailStore.jpg" 
    },
    { 
      title: "Professional Office Cleaning", 
      desc: "Routine and deep cleaning services for offices, promoting a healthy and productive work environment.", 
      image: "./CleanedCorporateOfiice.jpg" 
    },
    { 
      title: "Residential Cleaning", 
      desc: "Personalized cleaning services for homes, ensuring every corner is spotless and comfortable for your family.", 
      image: "./personSweeping.jpg" 
    },
    { 
      title: "Weekly Clean-ups", 
      desc: "Regular cleaning services scheduled weekly to maintain cleanliness and order in your space.", 
      image: "./manCleaningground.jpg" 
    },
    { 
      title: "Window Washing", 
      desc: "Professional window cleaning services to enhance visibility and brightness in your home or office.", 
      image: "./cleaningWindow.jpg" 
    },
    { 
      title: "Daily Clean Up Cleaning", 
      desc: "Daily cleaning services for high-traffic areas, ensuring cleanliness and hygiene at all times.", 
      image: "./handDusteruse.jpg" 
    },
    { 
      title: "Factory Cleaning", 
      desc: "Industrial-grade cleaning services for factories, ensuring compliance with safety regulations and cleanliness standards.", 
      image: "./ladyCleaningATable.jpg" 
    },
  ];

  // Filter services based on the search term in both title and description
  const filteredServices = services.filter(service =>
    service.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (service.desc && service.desc.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="services-page">
      <strong>
        <h1 className="main-quote">Our Services and Supplies</h1>
      </strong>
      <h1>Cleaning & Janitorial services for the best prices!</h1>

      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search our services!"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      {/* Services Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        {filteredServices.map((service, index) => (
          <div key={index} className="bg-white p-4 shadow-md">
            <img src={service.image} alt={service.title} className="w-full h-auto mb-4" />
            <h2 className="text-xl font-bold mb-2">{service.title}</h2>
            {service.desc && <h3 className="font-normal">{service.desc}</h3>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServicesPage;