import React from 'react';
import './aboutus1.css';

function AboutUsPage() {
  return (
    <div className="about-page">
     <strong> <h1 className='main-quote'>Who We Are!</h1></strong>

      <p>

Welcome to Echo Cleaning Services where our commitment goes beyond 
cleanliness since 1994. </p>
<div className="images-container-big">

<img className="images-container-big" src="./BusinessCardforEchoJan.png" alt="Business Card" />
</div>

<p>We believe in fostering a thriving community of 
dedicated cleaning professionals, valuing their contributions, and empowering 
them to deliver exceptional service. With a deep respect for the importance of our
 work, we strive not only to leave spaces immaculate but also to
 prioritize the well-being and satisfaction of both our team and our clients. </p>
    </div>
  );
}

export default AboutUsPage;
