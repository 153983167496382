import React, { useEffect } from "react"; 
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";



// Import from components folder
import Navbar from "./components/navbar1.jsx"; 
//import ContactButton from "./components/ContactButton.jsx";
import Footer from "./components/Footer1.jsx"; 


// Import from pages folder
import HomePage from "./Pages/HomePage"; 
import ServicesPage from "./Pages/ServicesPage"; 
import ContactUsPage from "./Pages/ContactUsPage"; 
import AboutUsPage from "./Pages/AboutUsPage"; 
import CareersPage from "./Pages/CareersPage"; 
import JobList from './Pages/JobsList'; // Import your JobList component

import './App.css';

/**
 *The site needs a lot of work 
 *Need the logo to be more present 
 *The nav bar needs to be able to turn into a hamburger format when small
 *The contact button needs to be able to send an email
 *the call better needs work
 *all form inputs need verification for formatting
 * need to add an AI for cusotmer service? (hold off on this)
*need to add a crash report bot/plugin

 * it need sbetter styling and formatiing
 *  it needs to be more user friendly
 * 
 * refernce the https://www.echojanitorialservice.com/ for pics and string for display
 */

// ... rest of your App component
function App() { 

  /*useEffect(() => {
    fetch("/api")
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then(data => console.log(data))
      .catch(error => console.error('There was a problem with the fetch operation:', error));
  }, []);*/


    useEffect(() => {
      document.title = "Echo Janitorial Services!"; // Set a default title
    }, []);

  return ( <Router> 
    <div className="App">
   <Navbar /> 
   <Routes> 

     {/*focus on home page for now */}



        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/start" element={<startWorker />} />
        <Route path="/JobsList" element={<JobList />} />


      </Routes>
      <Footer />
    </div>
  </Router>
  )}

  export default App;
