import React from 'react';
import './Homepage1.css';

function HomePage() {
  return (
    <div className="homepage">
      <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet"/>
      <header className="hero-section">
        <div className="hero-text">
          <h1 className="hero-title">Echo Janitorial Services</h1>
          <p className="hero-sub-title">JANITORIAL, CUSTODIAL, AND CLEANING SERVICES</p>
          <a href="tel:5192004064" className="call-button">Call Now</a>
        </div>
        <div className="hero-image">
          <img src="./handDusteruse.jpg" alt="Person Cleaning" />
        </div>
      </header>
      <strong><h1 className='main-quote'>We love the jobs you hate.</h1></strong>
      <h1>All services at very affordable rates.</h1>

      <div className="images-container-big">
        <img className="images-container-big" src="./BusinessCardforEchoJan.png" alt="Business Card" />
      </div>

      <div className="call-button">
        <a href="tel:5192004064" className="call-button">Call Us Now</a>
      </div>
      <br />

      <main>
        <br/>
        <br/>
        <h1>Echo Janitorial Quote</h1>
        <p>
          We are a fully-bonded-and-insured commercial and residential 
          cleaning company, offering efficient and personal service to London and surrounding areas.
        </p>

        <div className="images-container-big">
          <img src="./personWithMop.jpg" alt="Person With Mop" />
        </div>

        <h1>At Echo Cleaning Services, our commitment goes beyond cleanliness since 1994.</h1>

        <h1>Some Results:</h1>

        <div className="images-container">
          <img src="./BeforeNAfterPic2.jpg" alt="Before and After" />
          <img src="./BeforeNAfterPic1.png" alt="Before and After" />
          <img src="./SteamingStariCase.jpg" alt="Steaming Staircase" />
        </div>
      </main>
    </div>
  );
}

export default HomePage;
