import React from 'react';
import './CompCSS/footer1.css';
//import { BrowserRouter, Routes, Route } from 'react-router-dom';
//import JobList from '../Pages/JobsList'; // Import your JobList component


function Footer1() {
  return (
    <footer className="footer">
<div className="footer-column">
<strong><h3>Company</h3></strong>

        <ul>
        <li><a href="/">Home</a></li>
        <li><a href="/services">Services</a></li>
          <li><a href="/about">About Us</a></li>
          {/* <li><a href="/JobsList">Jobs List</a></li>
          <li><a href="/JobsList">Careers</a></li> */}

        </ul>
        
      </div>


      <div className="footer-column">
        <strong><h3>Business Inquiries</h3></strong>
        <ul>
          <li><a href="/contact">Contact Us</a></li>
          {/*<li><a href="/faq">FAQ</a></li>
          <li><a href="/terms">Terms & Conditions</a></li>*/}
        </ul>
      </div>
      <div className="footer-column">
      <strong><h3>Follow Us</h3></strong>
      <ul>
  <li><a href="https://www.facebook.com/echo.cleaning.7" target="_blank" rel="noopener noreferrer">Facebook</a></li>
  {/*<li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>*/}
  <li><a href="https://www.linkedin.com/company/echo-janitorial-services/" target="_blank" rel="noopener noreferrer">Linkedin</a></li>
</ul>
      </div>

    </footer>
  );
  
}

export default Footer1;
