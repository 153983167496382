import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import './CompCSS/navbar1.css';

function Navbar1() {
  const navLinksRef = useRef(null); // Create a ref for nav links
  const hamburgerRef = useRef(null); // Create a ref for hamburger

  useEffect(() => {
    const handleClickOutside = (event) => {
      // If the click is outside the nav links and hamburger, close the navbar
      if (navLinksRef.current && !navLinksRef.current.contains(event.target) && 
          hamburgerRef.current && !hamburgerRef.current.contains(event.target)) {
        navLinksRef.current.classList.remove('active');
      }
    };

    const handleClick = () => {
      if (navLinksRef.current) {
        navLinksRef.current.classList.toggle('active'); // Toggle the active class
      }
    };

    // Store the current value of navLinksRef in a variable
    const currentNavLinks = navLinksRef.current;

    if (currentNavLinks) {
      const links = currentNavLinks.querySelectorAll('a'); // Get all links
      links.forEach(link => {
        const linkClickHandler = () => {
          if (currentNavLinks) {
            currentNavLinks.classList.remove('active'); // Close navbar on link click
          }
        };
        link.addEventListener('click', linkClickHandler);

        // Cleanup listener for link
        return () => {
          link.removeEventListener('click', linkClickHandler);
        };
      });
    }

    if (hamburgerRef.current) {
      hamburgerRef.current.addEventListener('click', handleClick); // Add click listener to hamburger
    }

    document.addEventListener('mousedown', handleClickOutside); // Add click listener to document

    return () => {
      if (hamburgerRef.current) {
        hamburgerRef.current.removeEventListener('click', handleClick); // Cleanup hamburger listener
      }
      document.removeEventListener('mousedown', handleClickOutside); // Cleanup document listener
    };
  }, []); // Empty dependency array means this effect runs once after the component mounts

  return (
    <nav className="navbar container-fluid burger">
      <Link className="logo navbar-brand" to="/">
        <img src="./EchoLogo.jpg" alt="Logo" className="navbar-logo" />
      </Link>
      <ul className={'nav-links'} ref={navLinksRef}>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/services">Services</Link></li>
        <li><Link to="/about">About Us</Link></li>
        <li><Link to="/contact" className="contact-link">Contact Us</Link></li>
      </ul>
      <div className="hamburger" ref={hamburgerRef}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </nav>
  );
}

export default Navbar1;