import React, { useState } from 'react';

function JobList() {
  const [jobs, setJobs] = useState([]);
  const [jobTitle, setJobTitle] = useState('');
  const [applicationLink, setApplicationLink] = useState('');

  const addJob = () => {
    if (jobTitle && applicationLink) {
      const newJob = { title: jobTitle, link: applicationLink };
      setJobs([...jobs, newJob]);
      setJobTitle('');
      setApplicationLink('');
    } else {
      alert("Please fill in both fields.");
    }
  };

  const deleteJob = (index) => {
    const updatedJobs = jobs.filter((_, i) => i !== index);
    setJobs(updatedJobs);
  };

  return (
    <div className="JobsList-page"> {/**id="jobslist"  */}
      <h1>Job List Management</h1>
      <div className="job-form">
        <input
          type="text"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          placeholder="Job Title"
          required
        />
        <input
          type="text"
          value={applicationLink}
          onChange={(e) => setApplicationLink(e.target.value)}
          placeholder="Application Link"
          required
        />
        <button onClick={addJob}>Add Job</button>
      </div>
      <ul className="job-list">
        {jobs.map((job, index) => (
          <li key={index} className="job-item">
            <span>{job.title}</span>
            <div>
              <a href={job.link} target="_blank" rel="noopener noreferrer">Apply</a>
              <button onClick={() => deleteJob(index)}>Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default JobList;