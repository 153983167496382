import React, { useState } from 'react';
import './careerspage1.css';
import { profanity, CensorType } from "@2toad/profanity";

function CareersPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '', // Added phone number field
    resume: null // For file uploads
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    // Censor the input value for name, email, and phone fields
    if (type === 'text' || type === 'email' || type === 'number') {
      const censoredValue = profanity.censor(value, CensorType.Replace);
      setFormData({
        ...formData,
        [name]: censoredValue
      });
    } else if (type === 'file') {
      setFormData({
        ...formData,
        [name]: files[0] // Store the first file selected
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('access_key', '39e0e245-3572-4fe4-9aa7-3fc9aae0a96b');
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone); // Append the phone number
    formDataToSend.append('resume', formData.resume); // Append the file

    // Submit the form data to the API
    fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formDataToSend
    })
      .then(response => {
        if (response.ok) {
          console.log("Application submitted successfully");
          // Optionally, reset the form or show a success message
          setFormData({
            name: '',
            email: '',
            phone: '', // Reset phone number
            resume: null
          });
        } else {
          console.error("Failed to submit application:", response);
        }
      })
      .catch(error => {
        console.error("Error submitting application:", error);
      });
  };

  return (
    <div className="careers-page">
      <h1>Careers</h1>
      <p>Join our team! We are always looking for dedicated individuals to be part of our growing janitorial services company. Please fill out the form below if you are interested in a position.</p>

      <form onSubmit={handleSubmit} className="careers-form">
        <input type="hidden" name="access_key" value="sadf78asfa8d7asd8f" />

        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label>Phone Number:</label>
        <input
          type="tel" // Use 'tel' type for phone numbers
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />

        <label>Upload Resume:</label>
        <input
          type="file"
          name="resume"
          onChange={handleChange}
          required
        />

        <button type="submit">Submit Application</button>
      </form>
    </div>
  );
}

export default CareersPage;